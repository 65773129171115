.link-theme-primary{
    text-decoration: none;
    color: black;
    &:hover{
        color:#ce2029
    }
}


.bg-theme-primary{
    background-color: #ce2029;
    color: white !important;
}
/* .nav-right-bg{ */
    /* position: absolute; */
    /* max-width: 300% !important; */
    /* margin-left: 12rem; */
/* } */

body{
    overflow-x: hidden;
}

.nav-container {
    position: absolute;
    /* left: 50%; */
    /* transform: translate(-50%, 0px); */
    /* padding: 0 14rem; */
    display: flex;
    justify-content: space-around;
    padding-left: 14rem;
    gap: 25rem;
}



.header-right-container{
position:relative;
}
.header-right-text{
    position: absolute;
    right: 0;
    color: var(--white);
    top: 9px;
}
.nav-righ-mobile{
    display: none;
}

@media (max-width: 768px) {
    .header-right-container{
        display: none;
    }
    
    .nav-container{
       z-index: 9;
       gap: 0;
    }
    .logo-responsive{
        width: 200px;
        position: absolute;
        left: 20px;
        top: 5px;
        z-index: 999;
    }
    .header-right-text{
        display: flex;
        align-items: center;
        font-size: 8px;
        gap: 5px;
        right: 8px;
        top: 9px;
    }
    .nav-right-bg{
        display: none;
    }
    .responsive-svg{
        width: 15px;
    }
    .nav-righ-mobile{
        display: flex;
        position: relative;
        top: -110px;
        width: 166px;

    
    }
}